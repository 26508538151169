@import "../settings";

.repeater-icon {
  &__heading-row {
    @include breakpoint(medium) {
      max-width: calc(10 / 12 * 100%);
    }
  }

  //---------------
  // Default card styles.
  //---------------
  &__item {
    text-align: center;
  }

  &__item-heading {
    @extend h3 !optional;

    color: $secondary-color;
    margin: $global-margin 0;
    transition: 0.25s linear all;
  }

  &__image {
    text-align: center;
  }

  &__text {
    align-self: center;
    color: $secondary-color;
  }

  //---------------
  // Style options.
  //---------------
  &__grid {
    display: grid;
    grid-gap: $default-grid-gap;
    margin: 0;

    &--center {
      justify-content: center;
      text-align: center;
    }

    &--left {
      .repeater-icon__item,
      .repeater-icon__image,
      .repeater-icon__item-heading,
      .repeater-icon__text {
        text-align: left;
      }
    }

    &--card {
      .repeater-icon__item {
        border: 1px solid lightgray;
        padding: $global-padding * 2;
      }

      .repeater-icon__image,
      .repeater-icon__item-heading,
      .repeater-icon__text {
        text-align: left;
      }

      .repeater-icon__heading {
        align-self: start;
      }
    }

    //---------------
    // Layout options.
    //---------------
    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--3 {
      @include breakpoint(medium) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &--4 {
      @include breakpoint(medium) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &--5 {
      @include breakpoint(medium) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoint(large) {
        grid-template-columns: repeat(5, 1fr);

        .repeater-icon__item-heading {
          font-size: 26px;
        }

        .repeater-icon__image {
          max-width: 130px;
          margin: 0 auto;
        }
      }

      @include breakpoint(large) {
        @include mon-cols(10);
      }
    }
  }

  &--horizontal {
    .repeater-icon__item {
      display: grid;
      grid-gap: 0 $default-grid-gap;
      grid-template-columns: 1fr 4fr;

      @include breakpoint(large) {
        display: block;
      }
    }

    .repeater-icon__item-heading {
      align-self: center;
      margin-top: 0;
      @include breakpoint(large) {
        margin-top: $global-margin;
      }
    }

    .repeater-icon__item-heading,
    .repeater-icon__text {
      grid-column: 2;
      text-align: left;

      @include breakpoint(large) {
        text-align: center;
      }
    }

    .repeater-icon__icon {
      max-width: 50px;

      @include breakpoint( medium ) {
        max-width: 100%;
      }
    }
  }

  &--card {
    .repeater-icon__icon {
      -webkit-mask-image: url("../images/ideas-card-mask.svg");
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      // aspect-ratio: 1 / 1.3;
      height: 100%;
      mask-image: url("../images/ideas-card-mask.svg");
      mask-origin: fill-box;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }

  }
}

//---------------
// Hover states for cards if a link has been enabled.
//---------------
a.repeater-icon__item {
  display: block;
  transition: 0.25s linear all;

  .repeater-icon__text {
    color: $secondary-color;
  }

  &:hover {
    border-color: $primary-color;

    .repeater-icon__item-heading {
      color: $primary-color;
    }
  }
}
